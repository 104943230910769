import styled from 'styled-components';
import { color } from '../../theme/color';

type Color = keyof typeof color;

interface Props {
  value: number;
  max: number;
  step?: number;
  progressColor?: Color;
}

const ProgressBar = styled.input.attrs({
  type: 'range',
  role: 'slider',
})<Props>`
  flex: 1;
  -webkit-appearance: none;
  border-radius: 5px;
  border: none;
  transition: all 0.3s linear;
  cursor: pointer;
  position: relative;
  height: 5px;

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 5px;
  }

  &:hover::-webkit-slider-thumb {
    background-color: black;
    border-color: ${props => props.progressColor};
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid transparent;
    background-color: transparent;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    transform: translateY(-5px);
  }

  &::-moz-range-thumb {
    visibility: hidden;
    -moz-appearance: none;
    height: 12px;
    width: 12px;
  }

  &:hover::-moz-range-thumb {
    visibility: visible;
    background-color: black;
    border-color: ${props => props.progressColor};
  }
`;

ProgressBar.defaultProps = {
  min: 0,
  progressColor: color.orange,
};

export default ProgressBar;
