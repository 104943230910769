import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import ProgressBar from './common/ProgressBar';
import { color } from '../theme/color';

import mute from '../images/audio/mute.svg';
import volume from '../images/audio/volume.svg';

interface Props {
  player: any;
}

const VolumeControl: React.FC<Props> = ({ player }) => {
  const [currentVolume, setCurrentVolume] = useState<number | null>(null);
  const [muted, setMuted] = useState(false);

  useEffect(() => {
    if (currentVolume === null && player.volume !== undefined) {
      setCurrentVolume(player.volume);
    }
  }, [player, currentVolume]);

  if (currentVolume === null) {
    return null;
  }

  const toggleVolume = () => {
    const isMuted = !player.muted;

    player.muted = isMuted;

    setMuted(isMuted);
    setCurrentVolume(isMuted ? 0 : player.volume);
  };

  const handleVolumeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const volume = e.target.valueAsNumber;
    const isMuted = !volume;

    player.volume = volume;
    player.muted = isMuted;

    setMuted(isMuted);
    setCurrentVolume(volume);
  };

  const getProgressStyle = () => {
    const progress = currentVolume * 100;

    return {
      background: `linear-gradient(to right, ${color.white} ${progress}%, gray ${progress}%)`,
    };
  };

  return (
    <StyledWrapper>
      <StyledProgressBar
        max={1}
        step={0.05}
        value={currentVolume}
        progressColor={color.white}
        style={getProgressStyle()}
        onChange={handleVolumeChange}
      />
      <StyledIcon src={muted ? mute : volume} onClick={toggleVolume} />
    </StyledWrapper>
  );
};

export default VolumeControl;

const StyledIcon = styled.img`
  cursor: pointer;
  margin-left: 10px;
`;

const StyledProgressBar = styled(ProgressBar)`
  width: 0;
`;

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
  border-radius: 50px;

  &:hover {
    margin-left: 2rem;
    border-color: white;

    ${StyledProgressBar} {
      width: auto;
    }
  }
`;
