import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ProgressBar from './common/ProgressBar';
import { Text } from './common/Text';
import VolumeControl from './VolumeControl';
import play from '../images/audio/play.svg';
import pause from '../images/audio/pause.svg';
import { Box } from './common/Box';
import { theme } from '../theme/theme';

interface Props {
  src: string | null;
}

const AudioPlayer: FC<Props> = ({ src }) => {
  const ref = useRef<HTMLAudioElement>({} as HTMLAudioElement);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [paused, setPaused] = useState(true);

  useLayoutEffect(() => {
    setDuration(ref.current.duration);
  }, []);

  if (!src) {
    return null;
  }

  const player = ref.current!;

  const handleDurationChange = () => setDuration(player.duration);
  const handleTimeUpdate = () => setCurrentTime(player.currentTime);

  const padTime = (time: number) => {
    return time < 10 ? `0${time}` : time;
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(seconds / 3600);
    const restOfSeconds = Math.floor(seconds % 60);

    if (hours >= 1) {
      const restOfMinutes = Math.floor(minutes % 60);
      return `${hours}:${restOfMinutes}:${padTime(restOfSeconds)}`;
    }

    return `${minutes}:${padTime(restOfSeconds)}`;
  };

  const togglePause = () => {
    if (player.paused) {
      player.play();
      setPaused(false);
      return;
    }

    player.pause();
    setPaused(true);
  };

  const handleTimeSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const time = e.target.valueAsNumber;
    player.currentTime = time;
    setCurrentTime(time);
  };

  const getProgressStyle = () => {
    const progress = (currentTime / duration) * 100;

    return {
      background: `linear-gradient(to right, ${theme.colors.primary} ${progress}%, gray ${progress}%)`,
    };
  };

  return (
    <>
      <audio
        ref={ref}
        src={src}
        onDurationChange={handleDurationChange}
        onTimeUpdate={handleTimeUpdate}
        onPlay={() => setPaused(false)}
        onPause={() => setPaused(true)}
        preload="metadata"
      />
      <StyledWrapper>
        <StyledIcon src={paused ? play : pause} role="button" onClick={togglePause} alt="play" />
        <StyledTimer>
          <Text fontSize={[3, 4]}>{formatTime(currentTime)}</Text>
          <Text fontSize={[3, 4]} style={{ marginRight: 10, flex: 0 }}>
            /
          </Text>
          <Text fontSize={[3, 4]}>{formatTime(duration)}</Text>
        </StyledTimer>
        <ProgressBar
          value={Math.floor(currentTime)}
          max={Math.floor(duration || 1)}
          onChange={handleTimeSelect}
          style={getProgressStyle()}
        />
        <VolumeControl player={player} />
      </StyledWrapper>
    </>
  );
};

export default AudioPlayer;

const StyledTimer = styled(Box)`
  display: flex;
  justify-content: space-between;
  min-width: 140px;
  margin: 0 1.2rem;

  & > * {
    flex: 1;
  }
`;

const StyledIcon = styled.img`
  min-width: 20px;
  height: 30px;
  cursor: pointer;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  height: 38px;
`;
