import React from 'react';
import styled from 'styled-components';

import { PlayQuery_play_podcastLinks } from '../__generated__/graphql';
import { Box } from './common/Box';
import { UnderlinedLink } from './common/StyledLink';

import apple from '../images/audio/applepodcasts.svg';
import spotify from '../images/audio/spotify.svg';

interface Props {
  links: PlayQuery_play_podcastLinks | null;
}

const icons: Record<string, string> = {
  apple,
  spotify,
};

const PodcastLinks: React.FC<Props> = ({ links }) => {
  const linksToRender = Object.entries(links || {});

  const renderItem = ([platform, link]: [string, string]) => {
    if (!link) {
      return null;
    }

    return (
      <StyledLink key={platform} href={link} target="_blank" rel="noopener noreferrer">
        <StyledIcon src={icons[platform]} /> Listen on {platform}
      </StyledLink>
    );
  };

  return (
    <Box display="flex" flexDirection="column">
      {linksToRender.map(renderItem)}
    </Box>
  );
};

export default PodcastLinks;

const StyledLink = styled(UnderlinedLink)`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 100;
  text-decoration-thickness: 1px;
  margin-top: 20px;
`;

const StyledIcon = styled.img`
  height: 30px;
  margin-right: 15px;
`;
