import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { isIE } from 'react-device-detect';
import styled from 'styled-components';
import BrowserNotSupported from '../components/BrowserNotSupported';
import { InvertedBlockContentRenderer } from '../components/common/InvertedBlockContentRenderer';
import { Box } from '../components/common/Box';
import { Grid } from '../components/common/Grid';
import { InnerGrid } from '../components/common/InnerGrid';
import { Text } from '../components/common/Text';
import { useLocale } from '../components/CurrentLocale';
import { Layout } from '../components/Layout';
import { PlayTeaser } from '../components/PlayTeaser';
import { EmbeddedVideoSection } from '../components/sections/EmbeddedVideoSection';
import { SectionRenderer } from '../components/sections/SectionRenderer';
import { Seo } from '../components/Seo';
import { TalksProvider } from '../components/TalksProvider';
import { useThemeContext } from '../components/ThemeChanger';
import { TalksTopSection } from '../components/top/TalksTopSection';
import { useIsMobile } from '../hooks/useIsMobile';
import { useTranslation } from '../i18n/useTranslation';
import { grid } from '../theme/theme';
import { addURLPrefix } from '../utils/addURLPrefix';
import { extractWithLocale } from '../utils/extractWithLocale';
import { getSlugsFromPage } from '../utils/getSlugsFromPage';
import { isNotNull } from '../utils/isNotNull';
import { PageProps } from '../utils/PageProps';
import { combineFullSections } from '../utils/rawSectionHelpers';
import {
  PlayQuery,
  PlayQuery_moreVideos_nodes,
  PlayQuery_play_categories,
  Sections_SanityEmbeddedVideoSection,
} from '../__generated__/graphql';
import { ButtonMagnetic } from '../components/common/ButtonMagnetic';
import { color } from '../theme/color';
import { H3 } from '../components/common/Heading';
import AudioPlayer from '../components/AudioPlayer';
import PodcastLinks from '../components/PodcastLinks';
import Password from '../components/Password';

const Play: React.FC<PageProps<PlayQuery>> = ({ data, pageContext, location }) => {
  const [notSupportedBrowser, setNotSupportedBrowser] = useState(false);
  const themeCtx = useThemeContext();
  useEffect(() => {
    setNotSupportedBrowser(isIE);
    themeCtx.setTheme('dark');
  }, []);
  const locale = useLocale();

  const play = data.play!;

  if (!play) return null;

  const showMoreUrl = locale === 'sv' ? '/esatto-play' : '/en/esatto-play';

  const name = extractWithLocale(play.name, pageContext.locale) ?? '';
  const heading = extractWithLocale(play.heading, pageContext.locale) ?? '';
  const preamble = extractWithLocale(play.preamble, pageContext.locale) ?? '';
  const categoryName = (play.categories && play.categories[0]?.title) || null;

  const slugs = getSlugsFromPage(play);
  const sections = play.sections?.filter(isNotNull) ?? [];
  const rawSections = play._rawSections?.filter(isNotNull) ?? [];
  const fullSections = combineFullSections(sections, rawSections);

  const seoTitle = play.seo && extractWithLocale(play.seo.title, pageContext.locale);
  const seoDescription = play.seo && extractWithLocale(play.seo.description, pageContext.locale);
  const seoImage = play.seo?.seoImage?.asset?.gatsbyImageData?.images?.fallback?.src;

  const embeddedVideo = play.embeddedVideo as Sections_SanityEmbeddedVideoSection;
  const videoLength = embeddedVideo?.videoLength;

  const embeddedAudio = play.embeddedAudio;
  const image = play.image?.asset?.gatsbyImageData;

  const passwordHeading = extractWithLocale(play.passwordProtect?.heading, pageContext.locale);
  const passwordContent = extractWithLocale(play.passwordProtect?.content, pageContext.locale);

  const renderContent = () => {
    if (embeddedVideo?.videoUrl) {
      return (
        <EmbeddedVideoSection
          section={embeddedVideo}
          space={true}
          darkVersion={true}
          videoLength={videoLength}
        />
      );
    }

    return (
      <>
        {image && <GatsbyImage image={image as FluidObject} />}
        {embeddedAudio && <AudioPlayer src={embeddedAudio.url} />}
      </>
    );
  };

  const onTagClick = (category: PlayQuery_play_categories | null) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(showMoreUrl + '/' + category?.title?.toLowerCase().replace(' ', '-'), {
      state: { playTagName: category?.title },
    });
  };

  return notSupportedBrowser ? (
    <Layout
      locale={pageContext.locale}
      alternativeLinks={slugs}
      overlayHeaderOverContent={false}
      location={location}
      showFooter={false}
    >
      <Seo
        title={seoTitle || name || heading}
        description={seoDescription || preamble || ''}
        clearTemplate={play.seo?.clearTemplate}
        image={seoImage ? { url: seoImage, width: 1200, height: 630 } : null}
        location={location}
      />
      <Grid gridTemplateColumns={grid} data-component="wut">
        <BrowserNotSupported locale={pageContext.locale} />
      </Grid>
    </Layout>
  ) : (
    <TalksProvider show={play.showEsattoTalksBubble}>
      <Layout
        locale={pageContext.locale}
        alternativeLinks={slugs}
        overlayHeaderOverContent={false}
        location={location}
        forceHeaderTheme={'dark'}
      >
        <Seo
          title={seoTitle || name || heading}
          description={seoDescription || preamble || ''}
          clearTemplate={play.seo?.clearTemplate}
          image={seoImage ? { url: seoImage, width: 1200, height: 630 } : null}
          location={location}
        />
        <Password pageId={pageContext.id} password={play.passwordProtect?.password} heading={passwordHeading} content={passwordContent}>
          <Grid gridTemplateColumns={grid} data-component="wut" color={'white'} p={2} pb={6}>
            <InnerGrid>
              <Box gridColumn="1 / -1">
                {renderContent()}
                <TalksTopSection heading={heading} preamble={'#' + categoryName} />
              </Box>

              <Box gridColumn="1 / -1" px={[3, 2, 0]}>
                <InnerGrid>
                  <Box gridColumn={['1 / -1', '1 / -2']}>
                    <Box maxWidth={800} mb={4}>
                      <Text fontSize={[3, 4]} fontWeight={300} lineHeight={1.4}>
                        {preamble}
                      </Text>
                    </Box>
                    <Box maxWidth={800} mb={4}>
                      <InvertedBlockContentRenderer body={play._rawBody || []} />
                      <PodcastLinks links={play.podcastLinks} />
                    </Box>
                  </Box>

                  {/* <PlayTags gridColumn={['1 / -1', '-2 / -1']}>
                  {play.categories?.map(category => {
                    return (
                      <PlayTag onClick={() => onTagClick(category)}>#{category?.title}</PlayTag>
                    );
                  })}
                </PlayTags> */}
                </InnerGrid>
              </Box>
            </InnerGrid>
          </Grid>

          <MorePlays videos={data.moreVideos.nodes || ([] as PlayQuery_moreVideos_nodes[])} />
          <SectionRenderer sections={fullSections} />
        </Password>
      </Layout>
    </TalksProvider>
  );
};

export const query = graphql`
  query PlayQuery($id: String!) {
    play: sanityPlay(_id: { eq: $id }) {
      id
      name {
        sv
        en
      }
      image {
        asset {
          gatsbyImageData
        }
      }
      imageAltText {
        sv
        en
      }
      heading {
        sv
        en
      }
      slug {
        en {
          current
        }
        sv {
          current
        }
      }
      passwordProtect {
        heading {
          en

          sv
        }
        content {
          en

          sv
        }

        password
      }
      preamble {
        en
        sv
      }
      showEsattoTalksBubble
      _rawSections
      sections {
        ...Sections
      }
      _rawBody
      embeddedVideo {
        _key
        __typename
        videoUrl
        videoLength
        imagePreview {
          asset {
            gatsbyImageData
          }
        }
      }
      embeddedAudio {
        url
        length
      }
      categories {
        id
        title
      }
      podcastLinks {
        spotify
        apple
      }

      seo {
        title {
          sv
          en
        }
        description {
          sv
          en
        }
        clearTemplate
        follow
        index
        seoImage: image {
          asset {
            gatsbyImageData(layout: FIXED, width: 1200, height: 630)
          }
        }
      }
    }

    moreVideos: allSanityPlay(
      filter: { _id: { ne: $id }, isVisibleOnList: { ne: false } }
      limit: 6
    ) {
      nodes {
        id
        name {
          sv
          en
        }
        isVisibleOnList
        image {
          asset {
            gatsbyImageData
          }
        }
        imageAltText {
          sv
          en
        }
        slug {
          en {
            current
          }
          sv {
            current
          }
        }
        embeddedVideo {
          videoLength
        }
        embeddedAudio {
          url
          length
        }
        categories {
          id
          title
        }
        featuredImage: image {
          asset {
            gatsbyImageData(width: 800, height: 480)
          }
        }

        teaser {
          imageAltText {
            sv
            en
          }
          featuredTeaserImage: teaserImage {
            asset {
              gatsbyImageData(width: 800, height: 480)
            }
          }
        }
      }
    }
  }
`;

const MorePlays: React.FC<{ videos: PlayQuery_moreVideos_nodes[] }> = ({ videos }) => {
  const locale = useLocale();
  const t = useTranslation();
  const isMobile = useIsMobile();

  const showMoreUrl = locale === 'sv' ? '/esatto-play' : '/en/esatto-play';

  return (
    <>
      <PlayGrid
        gridTemplateColumns={grid}
        data-component="wut"
        color={'white'}
        p={5}
        isMobile={isMobile}
      >
        <Box pb={4} style={{ gridColumn: 2 }}>
          <H3 fontFamily={'Rubik'}>{t('similarPlays')}</H3>
        </Box>

        <InnerGrid columns={[1, 3]} gridGap={3}>
          {videos.map((item, index) => {
            const title = extractWithLocale(item.name, locale) || '';
            const slug = extractWithLocale(item.slug, locale)?.current ?? null;

            const category = (item.categories && item.categories[0]?.title) || undefined;
            const imageAltText =
              extractWithLocale(item.teaser?.imageAltText || item.imageAltText, locale) || '';

            const image =
              (item.teaser?.featuredTeaserImage || item.featuredImage)?.asset?.gatsbyImageData ??
              null;
            const url = addURLPrefix(slug, 'esatto-play', locale);

            const videoLength = item.embeddedVideo?.videoLength || undefined;
            const teaserType = item.embeddedAudio?.url ? 'audio' : 'video';

            return (
              <PlayTeaser
                kicker={category}
                title={title}
                fluidImage={image}
                imageAltText={imageAltText}
                link={url}
                index={index}
                trackLength={videoLength}
                type={teaserType}
                isFeatured={false}
                preamble={null}
              />
            );
          })}
        </InnerGrid>
      </PlayGrid>

      <Box style={{ display: 'flex', justifyContent: 'center', marginTop: -26, marginBottom: 96 }}>
        <PlayButtonMagnetic href={showMoreUrl}>{t('goToPlays')}</PlayButtonMagnetic>
      </Box>
    </>
  );
};

const PlayTags = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 36px 0;
`;

const PlayTag = styled.a`
  cursor: pointer;
  color: white;
  font-size: 20px;
  padding: 4px 0;
`;

const PlayGrid = styled(Grid) <{ isMobile?: boolean }>`
  background-color: #222222;
  clip-path: polygon(0 ${props => (props.isMobile ? '10px' : '20px')}, 100% 0, 100% 100%, 0% 100%);
`;

const PlayButtonMagnetic = styled(ButtonMagnetic)`
  border-color: #666;
  padding: 1rem 4.5rem;
  font-size: 1.15rem;

  &:hover::before {
    background-color: ${color.orange};
  }
`;

export default Play;
